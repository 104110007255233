import { useCallback } from 'react';
import { IBlocks } from '../store/reducers/editors';
import { useDispatch } from 'react-redux';
import {
  CursorTypes,
  DNDReducerActionEnum,
  IPosition,
  IXY,
} from '../store/reducers/settings/DNDReducer';
import { useTypeSelector } from './useTypesSelector';

export const useDND = () => {
  const dispatch = useDispatch();
  const pages = useTypeSelector((s) => s.editor.pages);
  const draftPages = useTypeSelector((s) => s.draftTemp.tempItem?.pages);
  const scale = useTypeSelector((s) => s.settings.dnd.scale);

  const setDragBlock = useCallback(
    (block: IBlocks, pageId: string) => {
      dispatch({ type: DNDReducerActionEnum.setDragBlock, payload: block });
      dispatch({
        type: DNDReducerActionEnum.setDragBlockPage,
        payload: pageId,
      });
    },
    [dispatch],
  );

  const setDragOverBlock = useCallback(
    (block: IBlocks, pageId: string) => {
      dispatch({ type: DNDReducerActionEnum.setDragOverBlock, payload: block });
      dispatch({
        type: DNDReducerActionEnum.setDragOverBlockPage,
        payload: pageId,
      });
    },
    [dispatch],
  );

  const setPageBlockDragged = useCallback(
    (payload: boolean) => {
      dispatch({ type: DNDReducerActionEnum.setPageBlockDragged, payload });
    },
    [dispatch],
  );

  const setCursor = useCallback(
    (payload: CursorTypes) => {
      dispatch({ type: DNDReducerActionEnum.setCursorDnD, payload });
    },
    [dispatch],
  );

  const setPos = useCallback(
    (payload: IPosition) => {
      dispatch({ type: DNDReducerActionEnum.setPos, payload });
    },
    [dispatch],
  );

  const setPosBefore = useCallback(
    (payload: IPosition) => {
      dispatch({ type: DNDReducerActionEnum.setPosBefore, payload });
    },
    [dispatch],
  );

  const setXY = useCallback(
    (payload: IXY) => {
      dispatch({ type: DNDReducerActionEnum.setXY, payload });
    },
    [dispatch],
  );

  const setDragWrapperScrollLeft = useCallback(
    (payload: number) => {
      dispatch({
        type: DNDReducerActionEnum.setDrageWrapperScrollLeft,
        payload,
      });
    },
    [dispatch],
  );

  const setScale = useCallback(
    (payload: number) => {
      dispatch({ type: DNDReducerActionEnum.setScale, payload });
    },
    [dispatch],
  );

  const setNoScroll = useCallback(
    (payload: boolean) => {
      dispatch({ type: DNDReducerActionEnum.setNoScroll, payload });
    },
    [dispatch],
  );

  const setPageScroll = useCallback(
    (payload: boolean) => {
      dispatch({ type: DNDReducerActionEnum.setPageScroll, payload });
    },
    [dispatch],
  );

  const waitForElm = async (selector: string) =>
    await new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });

  const rollHome = useCallback(
    async (toTop: boolean = false, scale = 1) => {
      setScale(scale);
      const sel =
        pages && pages[0]
          ? `div#PAGE_${pages[0].id}`
          : `div#PAGE_${draftPages && draftPages[0].id}`;
      await waitForElm(sel);
      const page = document.querySelector(sel);
      if (page) {
        const dw = document.querySelector('#drag_wrapper');
        const editor = document.querySelector('#editorContent');
        const halfDw =
          editor && dw
            ? editor.getBoundingClientRect().width / 2 -
              dw?.getBoundingClientRect().width / 2
            : 0;
        //@ts-ignore
        dw.scrollLeft = halfDw;

        // const dw = document.getElementById('drag_wrapper');
        // const editor = document.getElementById('editorContent');
        // const halfDw =
        //   dw && editor
        //     ? (editor.getBoundingClientRect().width / 2 -
        //         dw?.getBoundingClientRect().width / 2) *
        //       (1 - scale)
        //     : 0;
        // const halfPage = page ? page.getBoundingClientRect().width / 2 : 0;
        // let left = (halfPage - halfDw) * -1 - 20;
        // dispatch({
        //   type: DNDReducerActionEnum.setScalsOrigin,
        //   payload: `${halfDw}px 0px`,
        // });

        const top = toTop ? 50 : page.getBoundingClientRect().top;
        const pos = { left: dw?.scrollLeft || 0, top };
        // setPos(pos);
        setPosBefore(pos);
      }
      // const dw = document.getElementById('drag_wrapper');
      // const cont = document.querySelector('.editorContent');
      // if (dw && cont) {
      //   dw.addEventListener('scroll', (e: any) => {
      //   });

      //   let left = 0;
      //   let leftPos = 0;
      //   if (cont.clientWidth < dw.clientWidth) {
      //     left = cont.clientWidth / 2 - dw.clientWidth / 2;
      //     leftPos = (cont.clientWidth / 2 - dw.clientWidth / 2) * -1;

      //     if (pageScroll) {
      //       dw.scrollLeft += 117;
      //       setPageScroll(false);
      //     }

      //     if (!noScroll) {
      //       const pp = { top: 20, left: leftPos };
      //       setPosBefore(pp);
      //       setPos(pp);
      //       toTop && (dw.scrollTop = 20);
      //       dw.scrollLeft = left;
      //       setDragWrapperScrollLeft(dw.clientWidth / 2);
      //     }
      //     return;
      //   }

      //   if (cont.clientWidth > dw.clientWidth) {
      //     left = cont.clientWidth / 2 - dw.clientWidth / 2;
      //     // leftPos = (cont.clientWidth / 2 - dw.clientWidth / 2) * -1;

      //     if (pageScroll) {
      //       dw.scrollLeft += 117;
      //       setPageScroll(false);
      //     }
      //     if (!noScroll) {
      //       const pp = { top: 20, left: leftPos };
      //       setPosBefore(pp);
      //       setPos(pp);
      //       toTop && (dw.scrollTop = 20);
      //       dw.scrollLeft = left; // cont.clientWidth / 2 - dw.clientWidth / 2;
      //       setDragWrapperScrollLeft(dw.clientWidth / 2);
      //       setNoScroll(false);
      //     }
      //     return;
      //   }
      // }
    },
    [pages, setScale, setPosBefore, draftPages],
  );

  const rollUp = useCallback(
    (howMatch: number = 0.05) => {
      scale < 4.5 && setScale(Number(Number(scale + howMatch).toFixed(2)));
    },
    [scale, setScale],
  );

  const rollDown = useCallback(
    (howMatch: number = 0.05) => {
      scale > 0.15 && setScale(Number(Number(scale - howMatch).toFixed(2)));
    },
    [scale, setScale],
  );

  const goHome = useCallback(() => {
    setNoScroll(true);
    rollHome(true);
  }, [rollHome, setNoScroll]);

  const clearDND = useCallback(() => {
    setScale(1);
    setPos({ top: 20, left: 0 });
    setPosBefore({ top: 20, left: 0 });
    setNoScroll(false);
    setPageScroll(false);
    dispatch({ type: DNDReducerActionEnum.setScalsOrigin, payload: `` });
  }, [setScale, setPos, setPosBefore, dispatch, setPageScroll, setNoScroll]);

  return {
    setDragBlock,
    setDragOverBlock,
    setPageBlockDragged,
    setCursor,
    setPos,
    setPosBefore,
    setXY,
    setDragWrapperScrollLeft,
    setScale,
    setNoScroll,
    setPageScroll,
    rollHome,
    goHome,
    rollDown,
    rollUp,
    waitForElm,
    clearDND,
  };
};
