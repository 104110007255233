import { makeStyles } from "@material-ui/core";

export const ZoomStyle = makeStyles(()=>({
  zoom_wrapper: {
    position: 'fixed',
    width: 40,
    height: 130,
    top: 150,
    left: 30,
    background: '#FFFFFF',
    border: '1px solid #C7D6E6',
    borderRadius: 5,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    '& div:last-child:after': {
      display: 'none'
    },
    zIndex: 99999
  },
  btn: {
    position: 'relative',
    cursor: 'pointer',
    "&::after":{
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 24,
      height: 1,
      background: '#C7D6E6',
      bottom: -8,
      left: 0,
    },
    "& svg": {
      "& path": {
        fill: '#353E47',
      }
    },
    "&:hover":{
      "& svg": {
        "& path": {
          fill: '#3090E8',
        }
      }
    }
  }
}))
