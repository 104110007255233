import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useEditorSitemap } from '../../hooks/useEditorSitemap';
import useStyles from './styles';
import { GeneratePages } from '../../components/Editor/item/GeneratePage';
import cx from 'clsx';
import { setTitle } from '../../utils/setTitle';
import { IPages, InitPage } from '../../store/reducers/editors';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';
import { useDND } from '../../hooks/useDND';
import { v4 as uuid } from 'uuid';

export interface IEditorProps {
  id: string;
  pub?: boolean;
}

export const Editor = memo(({ id, pub }: IEditorProps) => {
  const cc = useStyles();
  const { setSnackbarValue } = useSnackbar();
  const { setNoScroll, setPageScroll, rollUp, rollDown } = useDND();
  const editor = useTypeSelector((s) => s.editor);
  const pages = useTypeSelector((s) => s.editor.pages);
  const { top, left } = useTypeSelector((s) => s.settings.dnd.pos);
  const scale = useTypeSelector((s) => s.settings.dnd.scale);
  const transformOrigin = useTypeSelector((s) => s.settings.dnd.scaleOrigin);
  const sidebarRoll = useTypeSelector(
    (s) => s.settings.editableBlock.sidebarRollup,
  );
  const [ctrl, setCtrl] = useState(false);

  const {
    removePageById,
    changeTitleFromPageById,
    addPageChildById,
    countPageChildById,
    putDraftServer,
    getDraftAccess,
    setPageComment,
    deletePageComment,
    updatePageComment,
  } = useEditorSitemap();

  const setPageTitle = useCallback(
    (page: IPages, newVal: string) => {
      // console.log('page', page);
      // console.log('!pub', !pub);
      if (!pub) {
        changeTitleFromPageById(page.id, newVal);
        return;
      }

      setSnackbarValue(
        'Нет прав на редактирование страницы',
        SnackbarType.Error,
      );
    },
    [pub, setSnackbarValue, changeTitleFromPageById],
  );

  const addPage = useCallback(
    (page: IPages) => {
      const count = countPageChildById(page.id);
      setNoScroll(true);
      if (count > 0) {
        setPageScroll(true);
      }
      // if (idx !== 0) {
      //   setNoScroll(true);
      // }
      if (!pub) {
        const newPage = {
          ...InitPage,
        };
        newPage.id = uuid();
        addPageChildById(page.id, newPage);
        return;
      }
      setSnackbarValue('Нет прав на добавление страниц', SnackbarType.Error);
    },
    [
      pub,
      setSnackbarValue,
      addPageChildById,
      countPageChildById,
      setNoScroll,
      setPageScroll,
    ],
  );

  const removePage = useCallback(
    (page: IPages) => {
      if (!pub) {
        removePageById(page.id);
        return;
      }
      setSnackbarValue('Нет прав на удаление страницы', SnackbarType.Error);
    },
    [pub, removePageById, setSnackbarValue],
  );

  const updateDraft = useCallback(async () => {
    if (!pub) {
      await putDraftServer();
      return;
    }
  }, [pub, putDraftServer]);

  const addCommentPage = useCallback(
    (page: IPages, comment: string) => {
      if (!pub) {
        setPageComment(page.id, comment);
        return;
      }

      setSnackbarValue('Нет прав на изменения комментария', SnackbarType.Error);
    },
    [setPageComment, pub, setSnackbarValue],
  );

  const removePageComment = useCallback(
    (page: IPages, idx: number) => {
      if (!pub) {
        deletePageComment(page.id, idx);
        return;
      }

      setSnackbarValue('Нет прав на изменения комментария', SnackbarType.Error);
    },
    [deletePageComment, pub, setSnackbarValue],
  );

  const updateCommentPage = useCallback(
    (page: IPages, val: string, idx: number) => {
      if (!pub) {
        updatePageComment(page.id, val, idx);
        return;
      }

      setSnackbarValue('Нет прав на изменения комментария', SnackbarType.Error);
    },
    [updatePageComment, pub, setSnackbarValue],
  );

  const roll = useCallback(
    (e: any) => {
      const delta = e.deltaY || e.detail || e.wheelDelta;
      if (!ctrl) return;

      e.preventDefault();

      if (delta < 0) rollUp();
      else rollDown();
    },
    [ctrl, rollUp, rollDown],
  );

  const down = useCallback(
    (e: any) => {
      if (e.ctrlKey === true || e.metaKey === true) {
        setCtrl(true);
        if (e.code === 'Equal' || e.code === 'Plus') {
          rollUp();
        }

        if (e.code === 'Minus') {
          rollDown();
        }
      }
    },
    [rollUp, rollDown],
  );

  const up = useCallback((e: any) => {
    if (e.ctrlKey === false && e.metaKey === false && e.altKey === false) {
      setCtrl(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', down);
    document.addEventListener('keyup', up);
    return () => {
      document.removeEventListener('keydown', down);
      document.removeEventListener('keyup', up);
    };
  }, [down, up]);

  useEffect(() => {
    if ('onwheel' in document) {
      // IE9+, FF17+
      document.addEventListener('wheel', roll, { passive: false });
    } else if ('onmousewheel' in document) {
      // устаревший вариант события
      //@ts-ignore
      document.addEventListener('mousewheel', roll, { passive: false });
    } else {
      // 3.5 <= Firefox < 17, более старое событие DOMMouseScroll пропустим
      //@ts-ignore
      document.addEventListener('MozMousePixelScroll', roll, {
        passive: false,
      });
    }

    return () => {
      if ('onwheel' in document) {
        // IE9+, FF17+
        document.removeEventListener('wheel', roll);
      } else if ('onmousewheel' in document) {
        // устаревший вариант события
        //@ts-ignore
        document.removeEventListener('mousewheel', roll);
      } else {
        // 3.5 <= Firefox < 17, более старое событие DOMMouseScroll пропустим
        //@ts-ignore
        document.removeEventListener('MozMousePixelScroll', roll);
      }
    };
  }, [roll]);

  useEffect(() => {
    getDraftAccess(id);
  }, [id, getDraftAccess]);

  useEffect(() => setTitle(`${editor.name} - structura.app`), [editor.name]);

  return (
    <div
      className={cx(
        cc.pagesWrapper,
        sidebarRoll ? cc.pagesWrapperUp : cc.pagesWrapperDown,
        cc.editorContent,
      )}
      id="editorContent"
      style={{
        transform: `scale(${scale}) translateY(${top}px) translateX(${left}px)`,
        transformOrigin,
      }}
    >
      {/* <PageHoverBtn
        removePage={removePage}
        addPage={addPage}
        updateDraft={updateDraft}
        addCommentPage={addCommentPage}
        removePageComment={removePageComment}
        updateCommentPage={updateCommentPage}
        pub={pub}
      /> */}
      {pages && pages.length && (
        <GeneratePages
          setPageTitle={setPageTitle}
          removePage={removePage}
          addPage={addPage}
          updateDraft={updateDraft}
          addCommentPage={addCommentPage}
          removePageComment={removePageComment}
          updateCommentPage={updateCommentPage}
          savePageAfterRender={true}
          editor={pages}
          top={true}
          pub={pub}
        />
      )}
    </div>
  );
});
