import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRoute } from 'wouter';
import { Editor } from './editor';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useDND } from '../../hooks/useDND';
import { Zoom } from '../../components/Editor/item/Zoom/Zoom';
import { IconsOutline } from '../../components/IconOutliner';
import { useEditorList } from '../../hooks/useEditorList';
import { useEditorSitemap } from '../../hooks/useEditorSitemap';
import { ProjectDescription } from '../../components/Editor/item/ProjectDescription';
import { useEffectOnce } from 'react-use';

export const EditorItem = () => {
  const [, params] = useRoute('/editor/:id');
  const profile = useTypeSelector((s) => s.profile.profile);
  const pageBlockDragged = useTypeSelector(
    (s) => s.settings.dnd.pageBlockDragged,
  );
  const cursor = useTypeSelector((s) => s.settings.dnd.cursorDnD);
  const ele = useRef(null);
  // const pos = useTypeSelector((s) => s.settings.dnd.pos);
  const posBefore = useTypeSelector((s) => s.settings.dnd.posBefore);
  const xy = useTypeSelector((s) => s.settings.dnd.xy);

  const editorId = useTypeSelector((s) => s.editor.id);
  const [loading, setLoading] = useState(false);
  const { setDraftId } = useEditorList();
  const { getDraft, setEvents } = useEditorSitemap();
  const {
    setCursor,
    // setPos,
    setPosBefore,
    setXY,
    // rollHome,
    goHome,
    rollDown,
    rollUp,
  } = useDND();

  const pub = useMemo(() => (profile === null ? true : false), [profile]);

  const mouseDownHandler = useCallback(
    (e: MouseEvent | TouchEvent) => {
      // console.log('e down', e);
      // e.preventDefault()
      if (!pageBlockDragged) {
        setCursor('grabbing');
        e instanceof MouseEvent && setXY({ x: e.clientX, y: e.clientY });
        e instanceof TouchEvent &&
          setXY({ x: e.touches[0].clientX, y: e.touches[0].clientY });
      }
    },
    [setXY, pageBlockDragged, setCursor],
  );

  const mouseMoveHandler = useCallback(
    (e: MouseEvent | TouchEvent) => {
      // console.log('e move', e);
      // e.preventDefault()

      if (
        cursor === 'grabbing' &&
        ele !== null &&
        ele.current &&
        !pageBlockDragged
      ) {
        // console.log('e.clientX', e.clientX, posBefore.left);
        // console.log('e.clientY', e.clientY);
        // console.log('xy.x', xy.x);
        // console.log('xy.y', xy.y);
        // const dx = e.clientX - xy.x + posBefore.left;
        // const dy = e.clientY - xy.y + posBefore.top;
        // setPos({ top: dy, left: dx });
        const dx =
          posBefore.left +
          xy.x -
          (e instanceof MouseEvent ? e.clientX : e.touches[0].clientX);
        const dy =
          posBefore.top +
          xy.y -
          (e instanceof MouseEvent ? e.clientY : e.touches[0].clientY);

        //@ts-ignore
        ele.current.scrollLeft = dx;
        //@ts-ignore
        ele.current.scrollTop = dy;
      }
    },
    [cursor, ele, xy, posBefore, pageBlockDragged],
  );

  const mouseUpHandler = useCallback(
    (e: any) => {
      // console.log('e up', e);
      // e.preventDefault()
      if (!pageBlockDragged) {
        setCursor('grab');
        setPosBefore({
          //@ts-ignore
          left: ele.current.scrollLeft,
          //@ts-ignore
          top: ele.current.scrollTop,
        });
      }
    },
    [pageBlockDragged, setCursor, setPosBefore],
  );
  // const goHome = useCallback(() => {}, [editorId]);

  const checkDrafts = useCallback(async () => {
    setLoading(true);
    try {
      if (params && params.id) {
        await getDraft(params.id);
        await setEvents(params.id, 'OPEN');
        setDraftId(params.id);
      }
    } finally {
      setLoading(false);
    }
  }, [getDraft, setEvents, setDraftId, params]);

  const init = useCallback(
    async (ev: any) => {
      // ev.preventDefault();
      // return (ev.returnValue = 'Are you sure you want to close?');
      params?.id && (await setEvents(params.id, 'OPEN'));
    },
    [params?.id, setEvents],
  );

  useEffectOnce(() => {
    checkDrafts();
    window.addEventListener('beforeunload', init);

    return () => {
      window.removeEventListener('beforeunload', init);
    };
  });

  useEffect(() => {
    if (ele.current !== null) {
      //@ts-ignoreele
      ele.current.addEventListener('mousemove', mouseMoveHandler);
      //@ts-ignore
      ele.current.addEventListener('touchmove', mouseMoveHandler);
      //@ts-ignoreele
      ele.current.addEventListener('mouseup', mouseUpHandler);
      //@ts-ignoreele
      ele.current.addEventListener('touchend', mouseUpHandler);
      //@ts-ignoreele
      ele.current.addEventListener('mousedown', mouseDownHandler);
      //@ts-ignoreele
      ele.current.addEventListener('touchstart', mouseDownHandler);
    }

    return () => {
      if (ele.current !== null) {
        //@ts-ignoreele
        ele.current.removeEventListener('mousemove', mouseMoveHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('touchmove', mouseMoveHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('mouseup', mouseUpHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('touchend', mouseUpHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('mousedown', mouseDownHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('touchstart', mouseDownHandler);
      }
    };
  }, [ele, mouseDownHandler, mouseUpHandler, mouseMoveHandler]);

  return (
    <>
      {!loading ? (
        <>
          <ProjectDescription />
          <Zoom
            in={() => rollUp(0.1)}
            out={() => rollDown(0.1)}
            goHome={goHome}
          />
        </>
      ) : (
        <></>
      )}

      <div
        id="drag_wrapper"
        className="drag_wrapper"
        style={{ cursor }}
        ref={ele}
      >
        {/* <div
          className="drag_content"
          id="drag_content"

        > */}

        {loading ? (
          <div
            style={{
              display: 'flex',
              height: 'calc(100vh - 90px)',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100vw',
            }}
          >
            <IconsOutline
              types="loading"
              defaultColor="#3090E8"
              width={66}
              height={66}
            />
          </div>
        ) : (
          <>
            {params && params.id && editorId && (
              <Editor id={params.id} pub={pub} />
            )}
          </>
        )}

        {/* </div> */}
      </div>
    </>
  );
};
